<template>
  <view-item title="维修">
    <template #head>
      <select-maintain
        v-model="head.data.vehicle"
        :ajax="{
          action: 'GET /enocloud/common/vehicle',
          params: (params, value) =>
            (params.payload = {
              quickSearch: value,
              rsexp:
                'id,plateNo,driver,driverCellphone,owner[id,name,cellphone,dob,salesman,address,salesmanType,category[name],serviceAdvisor[name]]'
            })
        }"
        :props="{ label: 'plateNo', value: '' }"
        value-key="id"
        placeholder="车牌/车主/手机号码/VIN"
        remote
        class="w-120"
        @change="head.vehicle.change"
      >
        <!-- <template #empty>
          <div class="flex flex-col gap-1 items-center pb-5">
            <img src="@service/assets/icon-select-empty.png" class="w-18 h-18" />
            <span class="text-xs text-gray-400">暂无搜索结果</span>
          </div>
        </template> -->

        <template #append>
          <en-option label="创建车牌车主" :value="{ id: 'xx' }" class="text-primary!"></en-option>
        </template>
      </select-maintain>
    </template>

    <template #operation>
      <en-button type="primary" @click="operation.addVehicleCustomer.click">创建客户车辆</en-button>

      <en-dropdown
        v-if="data?.id || (vehicle.data.id && customer.data.id)"
        v-loading="vehicle.loading || customer.loading"
        @command="operation.add.command"
      >
        <en-button type="primary">创建单据</en-button>
        <template #dropdown>
          <en-dropdown-item command="workorder">工单{{ data?.vehicleServiceGroup?.serviceId ? '(已创建)' : '' }}</en-dropdown-item>
          <en-dropdown-item command="quotation">估价{{ data?.vehicleServiceGroup?.serviceQuotationId ? '(已创建)' : '' }}</en-dropdown-item>
          <en-dropdown-item command="inspection">检测{{ data?.vehicleServiceGroup?.vehicleInspectionId ? '(已创建)' : '' }}</en-dropdown-item>
        </template>
      </en-dropdown>
      <slot name="operation"></slot>
    </template>

    <template #manifest>
      <slot name="manifest"></slot>
    </template>

    <view-item-content :disabled="!data?.id && !vehicle.data.id" characters>
      <div v-if="data?.id" class="relative h-full flex flex-col gap-6">
        <slot></slot>
      </div>

      <en-card v-if="!data?.id && vehicle.data.id" class="h-full" body-class="h-full">
        <en-tabs>
          <template #operation>
            <en-button type="primary" @click="vehicle.record.click">车辆档案</en-button>
          </template>
          <en-tab-pane label="车辆信息">
            <en-form label-position="left" label-suffix="：" :loading="vehicle.loading" class="w-3/5 grid grid-cols-3">
              <en-form-item label="车牌号">{{ vehicle.data.plateNo }}</en-form-item>
              <en-form-item label="车品牌">{{ vehicle.data?.vehicleSpec?.join(',') }}</en-form-item>
              <en-form-item label="VIN">{{ vehicle.data?.vin }}</en-form-item>
              <en-form-item label="发动机号">{{ vehicle.data?.engineNumber }}</en-form-item>
              <en-form-item label="车型类别">{{ vehicle.data?.type?.name }}</en-form-item>
              <en-form-item label="外观颜色">{{ vehicle.data?.color }}</en-form-item>
              <en-form-item label="交险承保">{{ vehicle.data?.compulsoryInsuranceCompany?.name }}</en-form-item>
              <en-form-item label="交险到期">{{ vehicle.data?.compulsoryInsuranceEndDate }}</en-form-item>
              <en-form-item label="商险承保">{{ vehicle.data?.commercialInsuranceCompany?.name }}</en-form-item>
              <en-form-item label="商险到期">{{ vehicle.data?.commercialInsuranceEndDate }}</en-form-item>
              <en-form-item label="年检日期">{{ vehicle.data?.annualInspectionDate }}</en-form-item>
              <en-form-item label="购买日期">{{ vehicle.data?.purchasingDate }}</en-form-item>
              <en-form-item label="前轮胎型号">{{ vehicle.data?.frontTireModel }}</en-form-item>
              <en-form-item label="后轮胎型号">{{ vehicle.data?.rearTireModel }}</en-form-item>
              <en-form-item label="色号">{{ vehicle.data?.colorCode }}</en-form-item>
            </en-form>
          </en-tab-pane>
        </en-tabs>

        <en-tabs>
          <template #operation>
            <en-button type="primary" @click="customer.record.click">客户档案</en-button>
          </template>
          <en-tab-pane label="客户信息">
            <en-form label-position="left" label-suffix="：" :loading="customer.loading" class="w-3/5 grid grid-cols-3">
              <en-form-item label="车主">{{ customer.data?.name }}</en-form-item>
              <en-form-item label="手机号码">{{ customer.data?.cellphone }}</en-form-item>
              <en-form-item label="客户分类">{{ customer.data?.category?.name }}</en-form-item>
              <en-form-item label="客户来源">{{ customer.data?.salesmanType?.message }}</en-form-item>
              <en-form-item label="客户介绍人">{{ customer.data?.salesman?.name }}</en-form-item>
              <en-form-item label="车主生日">{{ customer.data?.dob }}</en-form-item>
              <en-form-item label="详细地址">{{ customer.data?.address }}</en-form-item>
            </en-form>
          </en-tab-pane>
        </en-tabs>
      </en-card>
    </view-item-content>
  </view-item>

  <customer-record
    v-model="customerRecord.visible"
    :customer-data="data?.customer || customer.data"
    :vehicle-data="data?.vehicle || vehicle.data"
    @confirm="customerRecord.confirm"
  ></customer-record>

  <vehicle-record v-model="vehicleRecord.visible" :data="data?.vehicle || vehicle.data" @confirm="vehicleRecord.confirm"></vehicle-record>

  <workorder-detail
    v-model="workorder.visible"
    :customer="data?.customer || customer.data"
    :vehicle="data?.vehicle || vehicle.data"
    :vehicle-service-group="data?.vehicleServiceGroup"
    @confirm="workorder.confirm"
  ></workorder-detail>

  <quotation-detail
    v-model="quotation.visible"
    :customer="data?.customer || customer.data"
    :vehicle="data?.vehicle || vehicle.data"
    :vehicle-service-group="data?.vehicleServiceGroup"
    @confirm="quotation.confirm"
  ></quotation-detail>

  <inspection-detail
    v-model="inspection.visible"
    :customer="data?.customer || customer.data"
    :vehicle="data?.vehicle || vehicle.data"
    :vehicle-service-group="data?.vehicleServiceGroup"
    @confirm="inspection.confirm"
  ></inspection-detail>

  <customer-vehicle-form v-model="customerVehicleForm.visible" @confirm="customerVehicleForm.confirm"></customer-vehicle-form>
</template>

<script lang="ts">
import { CustomerVehicleForm, CustomerRecord, VehicleRecord } from '@enocloud/business'

import WorkorderDetail from '@service/components/workorder-detail.vue'
import QuotationDetail from '@service/components/quotation-detail.vue'
import InspectionDetail from '@service/components/inspection-detail.vue'

export default factory({
  components: {
    CustomerVehicleForm,
    CustomerRecord,
    VehicleRecord,
    WorkorderDetail,
    QuotationDetail,
    InspectionDetail
  },

  props: {
    data: Object as PropType<Record<string, any> | undefined>,
    init: Function,
    type: {
      type: String as PropType<'workorder' | 'quotation' | 'inspection'>,
      required: true
    }
  },

  emits: {
    confirm: (id: number | undefined) => true
  },

  config: {
    children: {
      head: {
        data: {
          vehicle: null as EnocloudCommonDefinitions['VehicleDto'] | null
        },
        children: {
          vehicle: {
            change(vehicle: EnocloudCommonDefinitions['VehicleDto'] & { id: string }) {
              this.init?.()
              if (vehicle.id === 'xx') {
                this.head.data.vehicle = null
                this.customerVehicleForm.visible = true
              } else if (typeof vehicle === 'object') {
                this.vehicle.data.id = vehicle.id
                this.vehicle.get()
                if (vehicle.owner) {
                  this.customer.data.id = vehicle.owner.id
                  this.customer.get()
                }
              }
            }
          }
        }
      },
      operation: {
        add: {
          command(option: string) {
            switch (option) {
              case 'workorder':
                if (this.data?.vehicleServiceGroup?.serviceId) return
                this.workorder.visible = true
                break
              case 'quotation':
                if (this.data?.vehicleServiceGroup?.serviceQuotationId) return
                this.quotation.visible = true
                break
              case 'inspection':
                if (this.data?.vehicleServiceGroup?.vehicleInspectionId) return
                this.inspection.visible = true
                break
            }
          }
        },
        addVehicleCustomer: {
          click() {
            this.customerVehicleForm.visible = true
          }
        }
      },
      vehicle: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.vehicle.data.id]
            }
          }
        },
        children: {
          record: {
            click() {
              this.vehicleRecord.visible = true
            }
          }
        }
      },
      customer: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/:customerId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.customer.data.id]
            }
          }
        },
        children: {
          record: {
            click() {
              this.customerRecord.visible = true
            }
          }
        }
      },
      vehicleRecord: {
        visible: false,
        confirm() {}
      },
      customerRecord: {
        visible: false,
        confirm() {}
      },
      workorder: {
        visible: false,
        confirm(id?: number) {
          if (this.type === 'workorder') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/workorder', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      },
      quotation: {
        visible: false,
        confirm(id?: number) {
          if (this.type === 'quotation') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/quotation', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      },
      inspection: {
        visible: false,
        confirm(id?: number) {
          if (this.type === 'inspection') {
            this.emit('confirm', id)
          } else {
            this.router.push('/service/business/inspection', (vm) => {
              vm.form.data.id = id
              vm.form.get()
            })
          }
        }
      },
      customerVehicleForm: {
        visible: false,
        confirm(res: { vehicle: EnocloudCommonDefinitions['VehicleDto']; customer: EnocloudCommonDefinitions['CustomerDto'] }) {
          this.vehicle.data = res.vehicle
          this.customer.data = res.customer
        }
      }
    }
  }
})
</script>
